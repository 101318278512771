import * as React from 'react';
import { bindActionCreators } from 'redux';
import { IPanelProps, PanelType, DefaultButton, TextField, MessageBar, MessageBarType, Label } from 'office-ui-fabric-react';
import { IUser, actionCreators, LicensesUtilization } from "../../../store/UsersListStore";
import { IPermissions, LicenseType, LicenseUtils } from '../../../store/permissions';
import PermissionsEdit from '../PermissionsEdit';
import { ApplicationState } from "../../../store/index";
import { connect } from 'react-redux';
import { actionCreators as tenantActionCreators, getDefaultPermissions, IDefaultPermissions, checkIfADUsersSyncEnabled } from "../../../store/Tenant";
import DirtyPanel from '../../common/DirtyPanel';
import { MultiActionConfirmationDialog } from '../../common/MultiActionConfirmationDialog';
import PersonPickerInput, { IPersonaPropsWithOrigin } from '../../common/inputs/PersonPickerInput';
import Link from '../../common/Link';
import { Dictionary, EntityType } from '../../../entities/common';
import FieldsEdit from '../../common/SubentityPanel/FieldsEdit';
import * as Metadata from "../../../entities/Metadata";
import { FieldConfig } from '../../common/SubentityPanel/FieldEditor';
import { OnChangeEvent } from '../../common/sectionsControl/uiControls/fieldsArea/FieldComponent';
import { PanelHeader } from '../../common/PanelHeader';
import { UserResourceFields } from '../../../store/user/common';

type UserEditOwnProps = {
    userId: string; onDismiss: () => void; readOnly: boolean; isCurrentUser: boolean;
}
type StateProps = {
    user: IUser;
    isADUsersSyncEnabled: boolean;
    licensesUtilization: LicensesUtilization;
    defaultPermissions?: IDefaultPermissions<IPermissions>;
    resourceFields: Metadata.Field[];
};
type ActionProps = {
    userActions: typeof actionCreators;
    tenantActions: typeof tenantActionCreators;
};
type UserEditProps = UserEditOwnProps & ActionProps & StateProps;

const primaryCommandLabel = "Save";
const cancelCommandLabel = 'Cancel';


const UserEdit = (props: UserEditProps) => {

    React.useEffect(() => {
        if (!props.defaultPermissions) {
            props.tenantActions.loadDefaultPermissions();
        }
    }, []);

    const { user, readOnly, isCurrentUser, defaultPermissions } = props;
    const [permissions, setPermissions] = React.useState(user?.permissions);
    const [isDirty, setIsDirty] = React.useState(false);
    const [license, setLicense] = React.useState(user?.license);
    const [promptLicenseChange, setPromptLicenseChange] = React.useState<LicenseType | undefined>();

    const [resourceAttributesChanges, setResourceAttributesChanges] = React.useState<Dictionary<unknown>>(user.resourceAttributes ?? {});
    const editResourceInfo = React.useMemo(() => ({ id: user.id, attributes: { ...user.resourceAttributes, ...resourceAttributesChanges } }), [resourceAttributesChanges, user]);
    const onResourceInfoChanged = React.useCallback<OnChangeEvent>(
        (f, v, extra) => {
            setResourceAttributesChanges((p) => ({ ...p, [f]: v, ...extra }));
            setIsDirty(true);
        },
        [resourceAttributesChanges]);

    React.useEffect(() => {
        if (user) {
            setLicense(user.license);
            props.userActions.loadPermissions(user.id, user.license);
        }
    }, [user?.id]);

    React.useEffect(() => setPermissions(user?.permissions), [user?.permissions]);

    const _onGetLicenseErrorMessage = () => {
        if (user.license !== license) {
            return LicenseUtils.getLicenseError(props.licensesUtilization, license, 1);
        }
        return undefined;
    }

    const _onPermissionsChange = (lic: LicenseType, changes: Partial<IPermissions>) => {
        if (lic === license) {
            _applyPermissionsChange(changes);
        } else if (lic === LicenseType.None || license === LicenseType.None) {
            _applyLicenseChange(lic, true);
        } else {
            setPromptLicenseChange(lic);
        }
    }

    const _applyLicenseChange = (lic: LicenseType, applyDefault: boolean) => {
        const perm: IPermissions = LicenseUtils.cutPermissions(lic,
            applyDefault
                ? LicenseUtils.appendPermissionsToRemove(getDefaultPermissions(lic, props.defaultPermissions) ?? LicenseUtils.buildEmptyPermissions(), permissions!)
                : permissions!
        );
        setIsDirty(true);
        setPermissions(perm);
        setLicense(lic);
        setPromptLicenseChange(undefined);
    }

    const _applyPermissionsChange = (changes: Partial<IPermissions>) => {
        const perm: IPermissions = { ...permissions!, ...changes };
        setIsDirty(true);
        setPermissions(perm);
    }

    const _onRenderFooterContent = (panelProps?: IPanelProps): JSX.Element | null => {
        return <div className="commands">
            {
                !readOnly && <DefaultButton
                    primary={true}
                    text={primaryCommandLabel}
                    iconProps={{ iconName: "Save" }}
                    onClick={_save}
                    disabled={!!_onGetLicenseErrorMessage()}
                />
            }
            <DefaultButton
                text={readOnly ? 'Close' : cancelCommandLabel}
                onClick={props.onDismiss}
            />
        </div>;
    }

    const _save = () => {
        props.userActions.savePermissions(props.userId, license, permissions!, () =>
            props.userActions.updateResourceAttributes([{ id: props.userId, resourceAttributes: resourceAttributesChanges }]));
        props.onDismiss();
    }

    const config: Dictionary<FieldConfig> = {};
    if (!props.user.linkedResource) {
        Object.keys(UserResourceFields).forEach((f) => config[f] = { readOnly: true });
    }

    return <DirtyPanel
        className="user-edit"
        isLightDismiss={true}
        type={PanelType.custom}
        customWidth="400px"
        isOpen={true}
        isDirty={isDirty}
        cancelCommandLabel={cancelCommandLabel}
        primaryCommandLabel={primaryCommandLabel}
        onDismiss={props.onDismiss}
        onRenderHeader={() => <PanelHeader header="User Settings" secondaryText="Apply user license and define permission level" ></PanelHeader>}
        onRenderFooterContent={_onRenderFooterContent}>
        <TextField
            label="User Name"
            disabled={true}
            value={user.fullName} />
        <div className="panel-area">
            <TextField
                className="grid-item"
                label="Email"
                disabled={true}
                value={user.email} />
            <div className="grid-item">
                <Label disabled={true}>Linked Resource</Label>
                <PersonPickerInput
                    className="readonly-color"
                    multichoice={false}
                    itemProps={{
                        onRenderPrimaryText: (p: IPersonaPropsWithOrigin) =>
                            <Link href={`/resource/${user.linkedResource?.id}`} target="_blank">
                                {p.text}
                            </Link>
                    }}
                    value={user.linkedResource}
                    disabled={true}
                    searchUrl="api/resource/find"
                />
            </div>
            <FieldsEdit
                fields={props.resourceFields}
                entity={editResourceInfo}
                config={config}
                displayFields={props.resourceFields.map(_ => _.name)}
                subentityType={EntityType.Resource}
                onChanged={onResourceInfoChanged}
            />
        </div>
        {permissions && defaultPermissions && <PermissionsEdit
            disableLicenseSelector={props.isADUsersSyncEnabled}
            license={license}
            readOnly={readOnly}
            isCurrentUser={isCurrentUser}
            permissions={permissions}
            onChange={_onPermissionsChange}
            errorMessage={_onGetLicenseErrorMessage()}
        />}
        {
            promptLicenseChange && <MultiActionConfirmationDialog
                onDismiss={() => setPromptLicenseChange(undefined)}
                dialogContentProps={{
                    title: 'Change license',
                    subText: 'Would you prefer to change the license only, retaining existing permissions, \
                            or change the license and overwrite permissions with the default permissions associated with the new license?'
                }}
                actionButtonProps={[
                    {
                        text: "Change License Only",
                        onClick: () => promptLicenseChange && _applyLicenseChange(promptLicenseChange, false)
                    },
                    {
                        text: "Change License and Apply Default Permissions",
                        onClick: () => promptLicenseChange && _applyLicenseChange(promptLicenseChange, true)
                    }
                ]}>
                <MessageBar isMultiline={true} messageBarType={MessageBarType.warning}>
                    Choosing to apply default permissions will result in the overwrite of all
                    pre-existing per-entity permissions with the license's default per-entity permissions.
                </MessageBar>
            </MultiActionConfirmationDialog>
        }
    </DirtyPanel>;
}

function mapStateToProps(state: ApplicationState, ownProps: UserEditOwnProps): StateProps {
    const byId = state.users.byId;
    const user = state.tenant.subscription.subscriptionId ? byId[ownProps.userId] : { ...byId[ownProps.userId], license: LicenseType.Regular };
    return {
        user: user,
        isADUsersSyncEnabled: checkIfADUsersSyncEnabled(state.tenant),
        licensesUtilization: state.users.licensesUtilization,
        defaultPermissions: state.tenant.defaultPermissions,
        resourceFields: Object.values(UserResourceFields).map(_ => state.fields.resource.byId[_])
    }
}
function mergeActionCreators(dispatch: any): ActionProps {
    return {
        userActions: bindActionCreators(actionCreators, dispatch),
        tenantActions: bindActionCreators(tenantActionCreators, dispatch),
    }
}
export default connect(mapStateToProps, mergeActionCreators)(UserEdit)