import React, { useEffect, useMemo } from 'react'
import { Section } from '../../entities/Metadata';
import { MyWork } from '../../store/MyWorkStore';
import { Resource, ResourceCalendar, actionCreators as ResourceActionCreators } from '../../store/ResourcesListStore';
import { actionCreators as TimeTrackingActions } from "../../store/TimeTrackingStore";
import { actionCreators as UserActionCreators } from "../../store/User";
import { DetailsSpinner } from '../common/Spinner';
import { SectionsContainer } from '../common/sectionsControl/SectionsContainer';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as CalendarActionCreators } from "../../store/CalendarStore";
import EntityHeader from '../common/EntityHeader';
import TimeTrackingConnectionButtons from '../timeTracking/connectors/TimeTrackingConnectionButtons';

enum UIControls {
    Summary = "MyTimeSummaryControl",
    TimeTracking = "TimeTrackingControl"
}

const MY_TIME_ID = "8db40938-3078-4331-b845-034967eeb10e";

const _sections: Section[] = [
    {
        id: "ae801a52-a4c7-4862-8af6-61629871c43a",
        isOpen: true,
        isSelected: true,
        isAccessible: true,
        name: "Summary",
        settings: { iconName: 'PPMXSectionSummary' },
        uiControls: [
            {
                id: "77e7e292-cb4f-474c-8e5c-ccdee1038b95",
                type: UIControls.Summary,
                settings: {}
            }
        ]
    },
    {
        id: "dce84df0-4b2c-4c15-8296-6f3511df39ef",
        isOpen: true,
        isSelected: true,
        isAccessible: true,
        name: "My Time",
        settings: { iconName: "Clock" },
        uiControls: [
            {
                id: "45516d25-2000-4f0b-bd34-868f41b34132",
                type: UIControls.TimeTracking,
                settings: {}
            }
        ]
    }
];

type StateProps = {
    work: MyWork[];
    isLoading: boolean;
    resource?: Resource;
    resourceCalendar?: ResourceCalendar;
};

type ActionProps = {
    userActions: typeof UserActionCreators;
    resourceActions: typeof ResourceActionCreators;
    timeTrackingActions: typeof TimeTrackingActions;
    calendarActions: typeof CalendarActionCreators;
};
type OwnProps = {};
type Props = StateProps & ActionProps & OwnProps;

function MyTime(props: Props) {
    useEffect(() => {
        props.userActions.loadResource();
        props.calendarActions.load();
    }, []);

    useEffect(() => {

        if (props.resource) {
            props.resourceActions.loadResource(props.resource.id, true);
            props.timeTrackingActions.loadReportedSummary(props.resource.id);
        }

    }, [props.resource])

    const { isLoading, resourceCalendar, resource, work } = props;
    const sections = useMemo(() => _sections.filter(_ => !isLoading || !_.uiControls.some(c => c.type === UIControls.TimeTracking)), [isLoading]);

    return (
        <DetailsSpinner isLoading={isLoading}>
            <EntityHeader
                name="My Time"
                nameTitle="My Time"
                logo={<div className="logo mytime-logo"></div>}
                buttons={<TimeTrackingConnectionButtons/>}
            />
            <SectionsContainer
                hideSideBar
                entity={
                    {
                        id: MY_TIME_ID,
                        sections: sections,
                        work: work,
                        resourceCalendar: resourceCalendar,
                        resource: resource
                    } as any
                }
            />
        </DetailsSpinner>
    );
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        work: state.resources.activeEntity?.work || [],
        isLoading: state.user.isInProcessing || state.resources.isLoading,
        resourceCalendar: state.resources.activeEntity?.usage?.calendar,
        resource: state.user.resource
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        userActions: bindActionCreators(UserActionCreators, dispatch),
        timeTrackingActions: bindActionCreators(TimeTrackingActions, dispatch),
        calendarActions: bindActionCreators(CalendarActionCreators, dispatch),
        resourceActions: bindActionCreators(ResourceActionCreators, dispatch)
    };
}

export default connect(mapStateToProps, mergeActionCreators)(MyTime);
