import { toPascalCase } from "../../components/utils/common";
import { Field, FieldGroup, FieldType } from "../../entities/Metadata";
import { AuthProvider, AuthProvidersMap, Dictionary } from "../../entities/common";
import { UserStatus } from "../User";
import { IUser, UserStatusMap } from "../UsersListStore";
import { LicenseType, LicenseTypeMap } from "../permissions";
import { nameof } from "../services/metadataService";

export const UserResourceFields =
{
    Role: "eb2102cc-73f7-4bf6-b879-50058d8fbd34",
    Department: "05460f7b-9c8c-402b-9723-a028c9c6da0d",
    Tags: "eba16ed3-b247-4449-afb2-1f28c33b430a"
}

const getUserStatusOptions = () => {
    const options = Object.keys(UserStatus).filter(key => !isNaN(Number(key))).reduce<Dictionary<string>>((acc, _) => {
        acc[_] = UserStatusMap[_].label;
        return acc;
    }, {});
    return options;
}

const getAuthProviderOptions = (authProviders: AuthProvider[]) => {
    const options = authProviders.reduce<Dictionary<string>>((acc, _) => {
        acc[_] = AuthProvidersMap[_].friendlyName;
        return acc;
    }, {});
    return options;
}

const getLicenseOptions = () => {
    const options = Object.keys(LicenseType).filter(key => !isNaN(Number(key))).reduce<Dictionary<string>>((acc, _) => {
        acc[_] = LicenseTypeMap[_].label;
        return acc;
    }, {});
    return options;
}

export const GetUserEntityFields = (authProviders: AuthProvider[]): Field[] => [{
    id: "951bcda5-8d6f-4ef7-a021-93f7a299ec74",
    label: "User Name",
    name: toPascalCase(nameof<IUser>("fullName")),
    type: FieldType.Text,
    isNative: false,
    isCustom: false,
    isReadonly: false,
    isSystem: false,
    group: FieldGroup.SystemFields,
}, {
    id: "9da42b61-2b1f-4ff5-a830-575c9f9fcc80",
    label: "Email",
    name: toPascalCase(nameof<IUser>("email")),
    type: FieldType.Text,
    isNative: false,
    isCustom: false,
    isReadonly: false,
    isSystem: false,
    group: FieldGroup.SystemFields,
}, {
    id: "3a7ea6ee-8201-4b2d-872e-96e2a131d654",
    label: "Status",
    name: toPascalCase(nameof<IUser>("status")),
    type: FieldType.Text,
    isNative: false,
    isCustom: false,
    isReadonly: false,
    isSystem: false,
    group: FieldGroup.SystemFields,
    settings: {
        editControl: "Dropdown",
        options: getUserStatusOptions(),
    }
}, {
    id: "f65236af-f53a-40a6-86f0-121041dc191f",
    label: "Auth Provider",
    name: toPascalCase(nameof<IUser>("authProvider")),
    type: FieldType.Text,
    isNative: false,
    isCustom: false,
    isReadonly: false,
    isSystem: false,
    group: FieldGroup.SystemFields,
    settings: {
        editControl: "Dropdown",
        options: getAuthProviderOptions(authProviders),
    }
}, {
    id: "e3d82a83-edd4-4429-a38c-f73a781e3a2f",
    label: "Logon Account",
    name: toPascalCase(nameof<IUser>("logonAccount")),
    type: FieldType.Text,
    isNative: false,
    isCustom: false,
    isReadonly: false,
    isSystem: false,
    group: FieldGroup.SystemFields,
}, {
    id: "7d42c957-4871-4597-b62c-66cdfc663637",
    label: "Linked Resource",
    name: toPascalCase(nameof<IUser>("linkedResource")),
    type: FieldType.Resource,
    isNative: false,
    isCustom: false,
    isReadonly: false,
    isSystem: false,
    group: FieldGroup.SystemFields,
}, {
    id: "982ff5b5-e224-4215-82e9-04cde876d848",
    label: "License",
    name: toPascalCase(nameof<IUser>("license")),
    type: FieldType.Text,
    isNative: false,
    isCustom: false,
    isReadonly: false,
    isSystem: false,
    group: FieldGroup.SystemFields,
    settings: {
        editControl: "Dropdown",
        options: getLicenseOptions(),
    }
},
];
