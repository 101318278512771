import * as H from 'history';
import * as React from 'react';

const urlHelperBuilder = (history: React.MutableRefObject<H.History | undefined>,
    location: React.MutableRefObject<H.Location | undefined>,
    query: URLSearchParams) => ({
        getUrlFilterId: () => query.get("filter") ?? null,
        getPreFilterId: () => query.get("prefilter") ?? null,
        openFilter: ({ filterId, preFilterId }: { filterId?: string, preFilterId?: string | null }) => {
            if (filterId !== undefined) {
                query.set('filter', filterId);
            }
            if (preFilterId !== undefined) {
                if (preFilterId) {
                    query.set('prefilter', preFilterId);
                } else {
                    query.delete('prefilter');
                }
            }

            history.current?.replace({
                ...location.current,
                search: query.toString(),
                state: undefined
            });
        }
    });

export const useUrlHelper = (history: H.History, location: H.Location) => {
    const { search } = location;
    const historyRef = React.useRef<H.History>();
    const locationRef = React.useRef<H.Location>();

    React.useEffect(() => {
        historyRef.current = history;
        locationRef.current = location;
    }, [location, history]);

    const urlHelper = React.useMemo(
        () => urlHelperBuilder(historyRef, locationRef, new URLSearchParams(search)),
        [search]);

    return urlHelper;
}
