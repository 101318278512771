import * as React from 'react';
import { EntityType, IBaseEntity } from "../../entities/common";
import { IWithFilters, IWithFiltersActions } from './extensibleEntity/ListMenu';
import * as Metadata from '../../entities/Metadata';
import { useUrlHelper } from "../../entities/Subentities";
import * as FiltersStore from '../../store/filters';
import { SourceType } from '../../store/ExternalEpmConnectStore';
import { default as GenericSubentityFilterPanel } from './SubentityFilterPanel';
import { BaseLocationStateFilter, LocationState } from '../../store/subentities/filters';
import { IWithActiveFilter } from '../../store/services/settingsService';
import { OnSaveSettings, PreferenceUpdates } from '../../store/services/viewSaver';
import { useEffectTillTrue, useIsOpen } from '../utils/effects';
import { nameof } from '../../store/services/metadataService';
import * as H from "history";
import { isCriteriaChange } from '../../store/filters';

type FilterActions = ReturnType<typeof FiltersStore.actionCreators.forEntity>;
type FiltersStore = FiltersStore.IFiltersState<Metadata.BaseFilterValue>;
type OnSetActiveFilter = (filterId: string, prefilterId?: string, persist?: boolean) => void;
type OnSetActivePreFilter = (prefilterId: string) => void;

type FilterData<T> = {
    preFilter?: Metadata.PreFilter<T>;
    activeFilter: Metadata.IFilter<Metadata.BaseFilterValue> | undefined;
    filters: Metadata.IFilter[];
    actions: IWithFiltersActions;
    isItemVisible: (item: T) => boolean;
    menu: IWithFilters;
}

export type IWithFilter<T> = {
    filter: FilterData<T>
}

export type IMaybeWithFilter<T> = {
    filter?: FilterData<T>
}

export type WithFilterProps<T> = {
    disabled?: string;

    preFilterItems?: Metadata.PreFilterOption<T>[];
    sourceType?: SourceType;
    entityType: EntityType;
    entities: T[];
    controlSettings: IWithActiveFilter;
    onSaveSettings?: OnSaveSettings;
    nonFilterableFields?: string[];

    defaultFilterName?: string;
    defaultPreFilterKey?: string;

    filterHelper: Metadata.IEntityFilterHelper<T>
    fields: Metadata.Field[];
    filtersActions: FilterActions;
    filters: Metadata.IFilter[];
    canManageConfiguration: boolean;

    history: H.History;
    location: H.Location;

    isItemVisibleBuilder?: IsItemVisiblePredicateBuilder<T>;
}

type UseFilterProps<T, TProps> = (props: TProps) => WithFilterProps<T>;

export const withFilter = <T extends IBaseEntity, TProps extends {}>(
    Component: React.ComponentType<TProps & IMaybeWithFilter<T>>,
    useFilterProps: UseFilterProps<T, Omit<TProps, keyof IMaybeWithFilter<T>>>,
    filterKey: string = FiltersStore.FilterKeys.Main,
) => {
    const SubentityFilterPanel = GenericSubentityFilterPanel<T>(filterKey);
    return (props: Omit<TProps, keyof IMaybeWithFilter<T>>) => {
        const { entityType, filterHelper, filters, controlSettings, onSaveSettings, sourceType, defaultFilterName, defaultPreFilterKey, isItemVisibleBuilder,
            filtersActions, fields, preFilterItems, history, location, nonFilterableFields, canManageConfiguration, disabled } = useFilterProps(props);

        const [isOpen, onOpen, onClose, setIsOpen] = useIsOpen(false);

        const activeFilter = disabled
            ? Helper.getFilterDefault(filters)
            : (Helper.getFilter(controlSettings.activeFilter?.filterId, filters)
                ?? Helper.getFilterByName(defaultFilterName, filters) ?? Helper.getFilterDefault(filters));
        const activePreFilter = controlSettings.activeFilter?.preFilterId === undefined
            ? Helper.getPreFilter(defaultPreFilterKey, preFilterItems)
            : Helper.getPreFilter(controlSettings.activeFilter?.preFilterId, preFilterItems);

        const setActiveFilter = (filterId: string, preFilterId?: string, persist?: boolean) => onSaveSettings?.(PreferenceUpdates.filter({ filterId, preFilterId }), persist);
        const setActivePreFilter = (preFilterId: string) => onSaveSettings?.(PreferenceUpdates.filter({ preFilterId }));

        usePreferencesAndUrlSync(history,
            location,
            activeFilter,
            filters,
            activePreFilter,
            preFilterItems,
            entityType,
            sourceType,
            onSaveSettings,
            setActiveFilter,
            setActivePreFilter,
            filtersActions);

        const actions = React.useMemo(
            () => buildFilterActions(filterHelper, sourceType, entityType, onOpen, setActiveFilter, filtersActions),
            [filterHelper, sourceType, onOpen, setActiveFilter, filtersActions]);

        const preFilter = React.useMemo(
            () => buildPreFilter(activePreFilter, preFilterItems, onSaveSettings),
            [onSaveSettings, preFilterItems, activePreFilter]);

        const isItemVisible = React.useMemo(() =>
            (isItemVisibleBuilder ?? defaultIsItemVisibleBuilder)(fields, activeFilter, preFilter, filterHelper),
            [fields, activeFilter, preFilter, filterHelper, isItemVisibleBuilder]);
        const redirectToFilter = React.useCallback(
            (filter: Metadata.IFilter) => setActiveFilter(filter.id),
            [setActiveFilter]);
        return <>
            <Component
                {...props as any as TProps}
                filter={{
                    activeFilter: activeFilter,
                    preFilter: preFilter,
                    filters: filters,
                    actions: actions,
                    isItemVisible: isItemVisible,
                    menu: {
                        useFilters: true,
                        preFilter: preFilter,
                        filters: filters,
                        activeFilter: activeFilter,
                        disableFilters: !!disabled,
                        filtersTitle: disabled,
                        ...actions
                    }
                }}
            />
            {
                isOpen && <SubentityFilterPanel
                    onSave={filtersActions.saveFilter}
                    activeFilter={activeFilter}
                    nonFilterableFields={nonFilterableFields}
                    subentityType={entityType}
                    canManageConfiguration={canManageConfiguration}
                    togglePanel={setIsOpen}
                    redirectToFilter={redirectToFilter}
                    entityFilterHelper={filterHelper}
                    fields={fields}
                />
            }
        </>
    }
}

export const isFilterChanged = <T,>(a: IMaybeWithFilter<T>, b: IMaybeWithFilter<T>) => {
    if (a == b) {
        return false;
    }

    const isFilterChanged = isCriteriaChange(a.filter?.activeFilter, b.filter?.activeFilter);
    const isPreFilterChanged = a.filter?.preFilter?.active !== b.filter?.preFilter?.active;
    return isFilterChanged || isPreFilterChanged;
}

const buildFilterActions = <T,>(filterHelper: Metadata.IEntityFilterHelper<T>,
    sourceType: SourceType | undefined,
    entityType: EntityType,
    onOpen: () => void,
    setActiveFilter: OnSetActiveFilter,
    actions: FilterActions): IWithFiltersActions => ({
        onFilterClick: setActiveFilter,
        onClearFilter: (filters?: Metadata.IFilter<Metadata.BaseFilterValue>[]) => {
            const autoFilterId = getClearFilterId(entityType, filters);
            if (autoFilterId) {
                setActiveFilter(autoFilterId);
            }
        },
        onAddFilterClick: () => {
            const newFilter = filterHelper.newFilter("New Filter", sourceType).build();
            actions.updateFilter(newFilter);
            setActiveFilter(newFilter.id, undefined, false);
            onOpen();
        },
        onEditFilterClick: id => {
            setActiveFilter(id);
            onOpen();
        },
        onCopyFilterClick: _ => actions.saveFilter(Metadata.Filter.copy(_), newFilter => {
            setActiveFilter(newFilter.id);
            onOpen();
        }),
        onRemoveFilterClick: id => actions.removeFilter(id)
    });

const usePreferencesAndUrlSync = <T extends IBaseEntity>(history: H.History,
    location: H.Location,
    activeFilter: Metadata.IFilter | undefined,
    filters: Metadata.IFilter[],
    activePreFilter: Metadata.PreFilterOption<T> | undefined,
    preFilterItems: Metadata.PreFilterOption<T>[] | undefined,
    entityType: EntityType,
    sourceType: SourceType | undefined,
    onSaveSettings: OnSaveSettings | undefined,
    setActiveFilter: OnSetActiveFilter,
    setActivePreFilter: OnSetActivePreFilter | undefined,
    filtersActions: FilterActions) => {
    const urlHelper = useUrlHelper(history, location, entityType);
    const urlFilter = Helper.getFilter(urlHelper.getUrlFilterId(), filters);
    const urlPreFilter = Helper.getPreFilter(urlHelper.getUrlPreFilterId(), preFilterItems);
    const isLoading = !onSaveSettings;

    // init url to user preference sync
    useEffectTillTrue(() => {
        if (isLoading) {
            return;
        }

        if (urlFilter && urlFilter.id !== activeFilter?.id) {
            const prefilter = urlPreFilter && urlPreFilter.key !== activePreFilter?.key ? urlPreFilter.key : activePreFilter?.key;
            setActiveFilter(urlFilter.id, prefilter);
        }
        else
        if (urlPreFilter && urlPreFilter.key !== activePreFilter?.key) {
            setActivePreFilter?.(urlPreFilter.key);
        }

        return true;
    }, [isLoading, urlFilter?.id, activeFilter?.id, urlPreFilter?.key, activePreFilter?.key]);

    const stateFilter = Helper.getLocationStateFilter(location, sourceType, entityType);
    React.useEffect(() => {
        if (isLoading) {
            return;
        }

        if (!stateFilter) {
            return;
        }

        const { filter, preFilter } = Helper.locationStateToFilter(stateFilter, filters, sourceType);
        if (filter?.id === Metadata.NEW_ID) {
            filtersActions.updateFilter(filter);
        }

        onSaveSettings?.(PreferenceUpdates.filter({
            filterId: filter?.id ?? null,
            preFilterId: preFilter ?? null
        }));
    }, [stateFilter, isLoading]);

    // sync url filter and user preference
    React.useEffect(() => {
        if (isLoading) {
            return;
        }

        if (activeFilter?.id !== urlFilter?.id) {
            activeFilter?.id && urlHelper.openFilter(activeFilter?.id);
        }
    }, [activeFilter?.id, urlFilter?.id, urlHelper, isLoading]);
}

export const getClearFilterId = (entytyType: EntityType, filters?: Metadata.IFilter<Metadata.BaseFilterValue>[]): string | undefined => {
    const filterId = clearFilterIds[entytyType];
    if (filterId) {
        return filterId;
    }
    if (filters) {
        return filters.find(_ => _.name.startsWith('All') && _.isBuiltIn)?.id ?? filters.find(_ => _.isBuiltIn)?.id;
    }
    return undefined;
}

const clearFilterIds = {
    [EntityType.ActionItem]: '7aeb0499-f010-4fdf-aa70-3f249173f16f',
    [EntityType.ChangeRequest]: '866c57ec-a37e-436f-89b9-661ecdef30c2',
    [EntityType.Dependency]: '7c521f9d-da6a-42c6-af93-6457e416bd0e',
    [EntityType.Idea]: 'beb00276-7a51-4f22-abb9-dcc39a26cde9',
    [EntityType.Issue]: '2182321e-ebfd-43af-9698-dbfb875a59ce',
    [EntityType.Iteration]: 'cfa63f4c-287e-405d-aa6a-bbe6b4349950',
    [EntityType.KeyDate]: '20cf51db-e34c-4faa-8b49-45c25d915a8b',
    [EntityType.KeyDecision]: '44432e88-b12a-41d2-80c9-47d9a81e0e8c',
    [EntityType.LessonLearned]: 'f3b4be95-e669-4136-86f7-f3aed33e8c7e',
    [EntityType.MyWork]: '0600bcc1-7fa7-4fa6-a714-3c8277bf148e',
    [EntityType.Risk]: '46a6e55c-e887-484e-b5b9-2ae51fd503a9',
    [EntityType.RoadmapItem]: 'c3b32e07-029a-44c5-88bf-1374c663777a',
    [EntityType.SteeringCommittee]: 'b6a9a6aa-bdcc-4e52-a49e-200a33e17bac',
    [EntityType.Task]: 'bac74312-f9eb-42b1-8da5-23e2093e742b',
    [EntityType.User]: '244a62a2-65e9-4755-813a-fe67845a62af',
}

type IsItemVisiblePredicateBuilder<T,> = (fields: Metadata.Field[],
    activeFilter: Metadata.IFilter | undefined,
    preFilter: Metadata.PreFilter<T> | undefined,
    filterHelper: Metadata.IEntityFilterHelper<T>) => (item: T) => boolean;

const defaultIsItemVisibleBuilder: IsItemVisiblePredicateBuilder<{}> = (fields, activeFilter, preFilter, filterHelper) => item => {
    if (fields.length === 0 || (preFilter?.active && !preFilter.active.predicate(item))) {
        return false;
    }

    if (!activeFilter) {
        return true;
    }

    const filterValue = activeFilter.value;
    const allAttributes = fields.map(_ => ({ type: "attributes", value: _, name: _.name, displayName: Metadata.getLabel(_) }));

    for (const type in filterValue) {
        const validateItem = filterHelper.helpersMap[type].validateItem(item, filterValue[type], allAttributes.filter(_ => _.type === type));
        if (!validateItem) {
            return false;
        }
    }

    return true;
}

const buildPreFilter = <T,>(activePreFilter: Metadata.PreFilterOption<T> | undefined,
    preFilterItems: Metadata.PreFilterOption<T>[] | undefined,
    onSaveSettings: OnSaveSettings | undefined) => preFilterItems
        ? {
            active: activePreFilter,
            items: preFilterItems,
            onChange: (_: Metadata.PreFilterOption<T> | undefined) => {
                onSaveSettings?.(PreferenceUpdates.filter({ preFilterId: _?.key ?? null }));
            }
        }
        : undefined;

const Helper = {
    getFilter: (filterId: string | null | undefined, filters: Metadata.IFilter[]) => filterId
        ? filters.find(_ => _.id == filterId)
        : undefined,
    getFilterDefault: (filters: Metadata.IFilter[]) => filters.find(_ => _.isDefault)
        ?? filters.find(_ => _.isBuiltIn),
    getFilterByName: (name: string | undefined, filters: Metadata.IFilter[]) => name ?
        filters.find(_ => _.name == name)
        : undefined,
    getPreFilter: <T,>(key: string | undefined | null, options: Metadata.PreFilterOption<T>[] | undefined) => key && options
        ? options.find(_ => _.key == key)
        : undefined,
    getLocationStateFilter: (location: H.Location, sourceType: SourceType | undefined, entityType: EntityType) => {
        const locationStateFilter = (location.state as LocationState | undefined)?.filters?.[entityType];
        return locationStateFilter && (locationStateFilter?.sourceType === undefined || locationStateFilter?.sourceType === sourceType)
            ? locationStateFilter
            : undefined;
    },
    locationStateToFilter: (locationStateFilter: BaseLocationStateFilter, filters: Metadata.IFilter[], sourceType: SourceType | undefined) => {
        const preFilter = locationStateFilter.hasOwnProperty(nameof<BaseLocationStateFilter>('prefilterKey'))
            ? locationStateFilter.prefilterKey
            : null;

        if (locationStateFilter.filterId) {
            return {
                filter: filters.find(_ => _.id == locationStateFilter.filterId),
                preFilter
            }
        }

        if (locationStateFilter.filter) {
            return {
                filter: { ...locationStateFilter.filter, type: sourceType },
                preFilter
            }
        }

        return { preFilter };
    }
}