import * as React from 'react';
import * as Metadata from "../../entities/Metadata";
import { FilterKeys, actionCreators } from '../../store/filters';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { AuthProvider, EntityType } from '../../entities/common';
import { bindActionCreators } from 'redux';
import { default as GenericEntitiesFilter } from '../common/EntitiesFilter';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { UserState } from "../../store/User";
import { contains, CommonOperations } from "../../store/permissions";
import { BaseFilterValue } from '../../entities/Metadata';
import { IUser } from '../../store/UsersListStore';
import { FilterHelper, LocationState, UserFilterValue } from '../../store/user/filters';
import { GetUserEntityFields, UserResourceFields } from '../../store/user/common';
import * as FiltersStore from '../../store/filters';
import { useUrlHelper } from '../utils/urlHelperBuilder';

type StateProps = {
    user: UserState;
    activeFilterId?: string;
    filters: Metadata.IFilter<BaseFilterValue>[];
    resourceFields: Metadata.Field[];
    authProviders: AuthProvider[];
}
type ActionProps = {
    actions: ReturnType<typeof actionCreators.forEntity>
}
type OwnProps = {
    filterKey: FilterKeys;
    onFilterChange: (filter: Metadata.IFilter<BaseFilterValue> | undefined) => void;
}

type Props = StateProps & ActionProps & OwnProps & RouteComponentProps<any>;

const EntitiesFilter = GenericEntitiesFilter<IUser>();

const UsersFilter = (props: Props) => {
    const getFilterById = (id?: string | null) => id ? props.filters.find(_ => _.id === id) : undefined;

    const urlHelper = useUrlHelper(props.history, props.location);

    const stateFilter = props.location.state as LocationState;
    const urlFilterId = urlHelper.getUrlFilterId();
    const activeFilterId = props.activeFilterId
        ?? stateFilter?.filter?.id
        ?? urlFilterId
        ?? Metadata.Filter.getAutoFilter(props.filters)?.id;

    const activeFilter = getFilterById(activeFilterId);

    const canManageConfiguration = contains(props.user.permissions.common, CommonOperations.ConfigurationManage);
    const updateFilter = (filter?: Metadata.IFilter<UserFilterValue>) => {
        if (!filter) {
            return;
        }

        props.actions.updateFilter(filter);
    }

    const entityFilterHelper = React.useMemo(
        () => new FilterHelper({
            userFields: GetUserEntityFields(props.authProviders),
            resourceFields: props.resourceFields
        }),
        []);

    React.useEffect(() => {
        if (!stateFilter) {
            return;
        }

        if (stateFilter.filter) {
            props.actions.updateFilter(stateFilter.filter);
        }

    }, [stateFilter]);

    React.useEffect(() => {
        const isFilterChanged = (activeFilter?.id ?? null) !== urlFilterId;
        if (isFilterChanged) {
            urlHelper.openFilter({
                filterId: activeFilter?.id,
                preFilterId: null
            });
        }
    }, [activeFilter?.id, urlFilterId, urlHelper]);

    React.useEffect(() => {
        props.onFilterChange(activeFilter);
    }, [activeFilter]);

    const [isFilterPanelOpen, setIsFilterPanelOpen] = React.useState(false);

    const _onActiveFilterChanged = (id?: string) => {
        props.actions.setActiveFilter(id);
    }

    return <EntitiesFilter
        fields={entityFilterHelper.UserFields}
        filters={props.filters}
        canManageConfiguration={canManageConfiguration}
        isFilterPanelOpen={isFilterPanelOpen}
        toggleFilterPanel={() => setIsFilterPanelOpen(!isFilterPanelOpen)}
        preFilter={undefined}
        activeFilter={activeFilter}
        onActiveFilterChanged={_onActiveFilterChanged}
        onFilterChanged={updateFilter}
        entityType={EntityType.User}
        entityFilterHelper={entityFilterHelper}
        suppressNavigation
    />
}
function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    const filters = FiltersStore.getFilter(state.filters, EntityType.User, ownProps.filterKey);
    return {
        filters: filters.all,
        activeFilterId: filters.active.filter?.id,
        user: state.user,
        resourceFields: Object.values(UserResourceFields).map(_ => state.fields.resource.byId[_]),
        authProviders: state.enviroment.authProviders,
    }
}

function mergeActionCreators(dispatch: any, ownProps: OwnProps): ActionProps {
    return {
        actions: bindActionCreators(actionCreators.forEntity(EntityType.User, ownProps.filterKey), dispatch)
    }
}

export default withRouter<OwnProps>(connect(mapStateToProps, mergeActionCreators)(UsersFilter));