import "./EditableGridCell.css";
import * as React from "react";
import { IFormInputComponent } from "../interfaces/IFormInputComponent";
import { Dictionary, EntityType, IBaseEntity } from "../../../entities/common";
import { IValidator } from "../../../validation";
import { Field } from "../../../entities/Metadata";
import { getId } from "office-ui-fabric-react";
import ErrorMessageCallout from "./ErrorMessageCallout";
import CellEditor from "./CellEditor";
import CellFormatter from "./CellFormatter";

export type FormatterProps<TImportMap> = { item: TImportMap, value: any };
export type EditorProps<TImportMap> = {
    item: TImportMap,
    value: any,
    onChange: (value: any) => void,
    onEditComplete: (value?: any) => void,
    inputRef?: (_: IFormInputComponent) => void;
};

type FieldCustomProps = {
    customFieldElementRender?: Dictionary<any>,
    customFieldValidatorBuilder?: Dictionary<(state: IBaseEntity, field: Field) => IValidator>
};

export type GridCellProps<T extends IBaseEntity> = {
    item: T,
    field: Field,
    entityType?: EntityType,
    onEditComplete: (value: any, extraUpdates?: Dictionary<any>) => void,
    withEditIcon?: boolean,
    className?: string,
    readonly?: boolean,
    disableNavigation?: boolean,
    isTimelineView: boolean,
    onEditModeChanged?: (inEdit: boolean) => void,
    getItemValue: (item: T, field: Field) => any,
    applyItemUpdates: (item: T, field: Field, value: any, extraUpdates?: Dictionary<any>) => T
} & FieldCustomProps;

const ERROR_MESSAGE_DELAY = 3000;

export const EditableGridCell = <T extends IBaseEntity>(props: GridCellProps<T>) => {
    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    const [item, setItem] = React.useState(props.item);
    const [error, setError] = React.useState<string | undefined>();

    React.useEffect(() => setItem(props.item), [props.item])
    const controlId = React.useMemo(() => getId(), []);

    return isEditing
        ? <CellEditor {...props}
            onClose={(err?: string) => {
                err && setError(err);
                setIsEditing(false);
                props.onEditModeChanged?.(false);
                setTimeout(() => setError(undefined), ERROR_MESSAGE_DELAY);
            }}
            onEditComplete={(val: any, extraUpdates?: Dictionary<any>) => {
                setItem(props.applyItemUpdates(item, props.field, val, extraUpdates));
                props.onEditComplete(val, extraUpdates);
            }}
        />
        : <>
            <CellFormatter {...props}
                controlId={controlId}
                item={item}
                value={props.getItemValue(item, props.field)}
                onEditClick={() => {
                    setIsEditing(true);
                    props.onEditModeChanged?.(true);
                }} />
            {!!error && <ErrorMessageCallout controlId={controlId} error={error} />}
        </>;
}
