import { distinct, distinctByKey, toDictionaryById } from "../components/utils/common";
import { Dictionary } from "../entities/common";

export const NotEnoughPermissionsMessage = "Sorry, you don't have the permission to access this page. Contact your administrator for necessary permission.";

export enum LicenseType {
    None = 0,
    Regular = 1,
    Viewer = 2
}

export const LicenseTypeMap: { [i: number]: { label: string, cssClassName: string } } = {
    [LicenseType.None]: { label: "Not Applied", cssClassName: "none" },
    [LicenseType.Regular]: { label: "User", cssClassName: "regular" },
    [LicenseType.Viewer]: { label: "Team Member", cssClassName: "viewer" }
}

export enum CommonOperations {
    None = 0x0,

    ResourceView = 0x10,
    UserView = 0x20,
    ScheduleView = 0x40,
    ODataView = 0x80,
    BudgetManage = 0x100,
    ConfigurationView = 0x200,
    IdeationView = 0x400,
    BillingView = 0x800,
    ConnectionManage = 0x1000,
    InsightsView = 0x2000,
    PrioritizationView = 0x4000,

    ResourceManage = 0x1 | ResourceView,
    UserManage = 0x2 | UserView,
    ScheduleManage = 0x4 | ScheduleView,
    ODataManage = 0x8 | ODataView,
    ConfigurationManage = 0x8000 | ConfigurationView,
    IdeationManage = 0x10000 | IdeationView,
    BillingManage = 0x20000 | BillingView,
    PrioritizationManage = 0x40000 | PrioritizationView,

    TimeTrackingView = 0x80000,
    TimeTrackingManage = 0x100000 | TimeTrackingView,

    AdministrateView = ResourceView | UserView | ScheduleView | ODataView | BudgetManage | ConfigurationView
    | PrioritizationView | BillingView | ConnectionManage | InsightsView | TimeTrackingView,

    Administrate = ResourceManage | UserManage | ScheduleManage | ODataManage | BudgetManage | ConfigurationManage
    | PrioritizationManage | BillingManage | ConnectionManage | InsightsView | TimeTrackingManage,
}

export enum ResourceOperations {
    None = 0x0,
    Create = 0x1,
    Read = 0x2,
    Update = 0x4,
    Collaborate = 0x8,
    Configure = 0x10,

    All = Create | Read | Collaborate | Update | Configure
}

type StandardEnum<T> = {
    [id: string]: T | string;
    [nu: number]: string;
}
export function contains<T extends StandardEnum<unknown>>(operations: T[keyof T], toMatch: T[keyof T]): boolean {
    return (((operations as any) & (toMatch as any)) as any) === (toMatch as any);
}
export function containsAny<T extends StandardEnum<unknown>>(operations: T[keyof T], toMatch: T[keyof T]): boolean {
    return (((operations as any) & (toMatch as any)) as any) !== 0;
}
export function toggle<T extends StandardEnum<unknown>, T2>(operations: T[keyof T], toMatch: T[keyof T], on?: boolean): T2 {
    return (on
        ? ((operations as any) | (toMatch as any))
        : ((operations as any) & ~(toMatch as any))) as unknown as T2;
}

export function notNone(operations: IEntityPermissions) {
    return operations.global !== ResourceOperations.None || operations.entities !== ResourceOperations.None;
}

export function canRead(operations: IEntityPermissions) {
    return canPerformOperation(operations, ResourceOperations.Read);
}
export function canReadEntity(operations: IEntityPermissions, layoutMap?: Dictionary<string>, entityId?: string) {
    return contains(operations.global, ResourceOperations.Read) ||
        (contains(operations.entities, ResourceOperations.Read) && entityId && layoutMap?.hasOwnProperty(entityId));
}

export function canCreate(operations: IEntityPermissions) {
    return canPerformOperation(operations, ResourceOperations.Create);
}

export function canUpdate(operations: IEntityPermissions) {
    return canPerformOperation(operations, ResourceOperations.Update);
}

function canPerformOperation(operations: IEntityPermissions, operation: ResourceOperations) {
    return contains(operations.global, operation) || contains(operations.entities, operation);
}

export function canCollaborate(operations: IEntityPermissions) {
    return contains(operations.global, ResourceOperations.Collaborate) || contains(operations.entities, ResourceOperations.Collaborate);
}

export interface IGlobalPermissions {
    global: ResourceOperations;
}

export interface IEntityPermissions extends IGlobalPermissions {
    entities: ResourceOperations
}

interface IServerResourcePermission {
    id: string;
    managerIds: string[];
    name: string;
    operations: ResourceOperations;
    layoutId?: string;
}

interface IServerResourcePermissions extends IGlobalPermissions {
    layoutId?: string,
    entities: IServerResourcePermission[]
}

export interface IServerPermissions {
    common: CommonOperations;
    portfolio: IServerResourcePermissions;
    program: IServerResourcePermissions;
    project: IServerResourcePermissions;
    objective: IServerResourcePermissions;
    roadmap: IServerResourcePermissions;
    challenge: IServerResourcePermissions;
}

export interface IPermissions {
    common: CommonOperations;
    portfolio: IResourcePermissions;
    program: IResourcePermissions;
    project: IResourcePermissions;
    objective: IResourcePermissions;
    roadmap: IResourcePermissions;
    challenge: IResourcePermissions;
}

export interface IEntityInfo {
    id: string;
    name: string;

    disabled?: boolean;
    title?: string;
}

export interface ILayoutInfo {
    id: string;
    name: string;
    layoutId?: string;
}

export interface IResourcePermissions extends IGlobalPermissions {
    layoutId?: string,
    entities: {
        allIds?: string[];
        read: IEntityInfo[];
        collaborate: IEntityInfo[];
        update: IEntityInfo[];
        configure: IEntityInfo[];
        layout: ILayoutInfo[];
    }
}

export function toServerResourcePerms(permissions: IResourcePermissions): IServerResourcePermissions {
    const entities: Partial<IServerResourcePermission>[] = [];

    const allIds = permissions.entities.allIds ?? [];
    const readMap = toDictionaryById(permissions.entities.read);
    const updateMap = toDictionaryById(permissions.entities.update);
    const collaborateMap = toDictionaryById(permissions.entities.collaborate);
    const configureMap = toDictionaryById(permissions.entities.configure);
    const layoutMap = toDictionaryById(permissions.entities.layout);
    allIds.concat(Object.keys(readMap)).concat(Object.keys(updateMap)).concat(Object.keys(collaborateMap)).concat(Object.keys(layoutMap)).filter(distinct).forEach(e => {
        const entity: { id: string, operations: ResourceOperations, layoutId?: string } = { id: e, operations: ResourceOperations.None, layoutId: layoutMap[e]?.layoutId };
        if (readMap[entity.id]) {
            entity.operations |= ResourceOperations.Read;
        }
        if (collaborateMap[entity.id]) {
            entity.operations |= ResourceOperations.Collaborate;
        }
        if (updateMap[entity.id]) {
            entity.operations |= ResourceOperations.Update;
        }
        if (configureMap[entity.id]) {
            entity.operations |= ResourceOperations.Configure;
        }

        if (entity.layoutId || entity.operations > ResourceOperations.None || !!~allIds.indexOf(entity.id)) {
            entities.push(entity);
        }
    });

    return {
        global: permissions.global,
        layoutId: permissions.layoutId,
        entities: entities as IServerResourcePermission[]
    }
}

export function toServerPermissions(permissions: IPermissions): IServerPermissions {
    return {
        common: permissions.common,
        portfolio: toServerResourcePerms(permissions.portfolio),
        program: toServerResourcePerms(permissions.program),
        project: toServerResourcePerms(permissions.project),
        objective: toServerResourcePerms(permissions.objective),
        roadmap: toServerResourcePerms(permissions.roadmap),
        challenge: toServerResourcePerms(permissions.challenge)
    }
}

export function toPermissions(permissions: IServerPermissions, userId?: string, license?: LicenseType): IPermissions {
    return {
        common: permissions.common,
        portfolio: toResourcePermissions("Portfolio", permissions.portfolio, userId, license),
        program: toResourcePermissions("Program", permissions.program, userId, license),
        project: toResourcePermissions("Project", permissions.project, userId, license),
        objective: toResourcePermissions("Objective", permissions.objective, userId, license),
        roadmap: toResourcePermissions("Roadmap", permissions.roadmap, userId, license),
        challenge: toResourcePermissions("Business Challenge", permissions.challenge, userId, license),
    }
}

function toResourcePermissions(entityLabel: string, serverPerms: IServerResourcePermissions, userId: string | undefined, license: LicenseType | undefined): IResourcePermissions {
    const message = license === LicenseType.Regular
        ? `The user is a Manager of this ${entityLabel}. Managers can view and edit their ${entityLabel}s by default.`
        : `The user is a Manager of this ${entityLabel}. Managers can view their ${entityLabel}s by default.`;

    const map = (_: IServerResourcePermission) => {
        const isManager = !!userId && !!_.managerIds.find(__ => __ === userId);
        return { ..._, disabled: isManager, title: isManager ? message : undefined };
    };

    const sort = (a: IEntityInfo, b: IEntityInfo) => a.disabled === b.disabled
        ? a.name.localeCompare(b.name)
        : (+(a.disabled || 0)) - (+(b.disabled || 0));

    return {
        global: serverPerms.global,
        layoutId: serverPerms.layoutId,
        entities: {
            allIds: serverPerms.entities.map(_ => _.id),
            read: serverPerms.entities.filter(_ => contains(_.operations, ResourceOperations.Read)).map(map).sort(sort),
            update: serverPerms.entities.filter(_ => contains(_.operations, ResourceOperations.Update)).map(map).sort(sort),
            configure: serverPerms.entities.filter(_ => contains(_.operations, ResourceOperations.Configure)).map(map),
            collaborate: serverPerms.entities.filter(_ => contains(_.operations, ResourceOperations.Collaborate)).map(map).sort(sort),
            layout: serverPerms.entities.filter(_ => !!_.layoutId)
        }
    }
}

export type LicensesUtilization = {
    users: LicenseUtilization;
    viewers: LicenseUtilization;
}

type LicenseUtilization = {
    total: number;
    allocated: number;
}

export namespace LicenseUtils {
    const NONE_LICENSE_PERMISSIONS = buildEmptyPermissions();

    export function buildEmptyPermissions(layoutId?: string): IPermissions {
        return {
            common: CommonOperations.None,
            portfolio: buildEmptyResourcePermissions(layoutId),
            program: buildEmptyResourcePermissions(layoutId),
            project: buildEmptyResourcePermissions(layoutId),
            objective: buildEmptyResourcePermissions(layoutId),
            roadmap: buildEmptyResourcePermissions(layoutId),
            challenge: buildEmptyResourcePermissions(layoutId)
        }
    }
    function buildEmptyResourcePermissions(layoutId?: string): IResourcePermissions {
        return { global: ResourceOperations.None, layoutId, entities: { read: [], collaborate: [], update: [], configure: [], layout: [] } };
    }

    export function getAvailableLicense(utilization: LicensesUtilization): LicenseType {
        if (getAvailableCount(utilization.users) > 0) {
            return LicenseType.Regular;
        }

        if (getAvailableCount(utilization.viewers) > 0) {
            return LicenseType.Viewer;
        }

        return LicenseType.None;
    }

    export function getLicenseError(utilization: LicensesUtilization, license: LicenseType, requestedCount: number) {
        if (license === LicenseType.None) {
            return "";
        }

        const availableCount = getAvailableCount(license === LicenseType.Regular ? utilization.users : utilization.viewers);
        if (requestedCount > availableCount) {
            return requestedCount > 1
                ? `Not enough licenses (${availableCount} available, ${requestedCount} required)`
                : "License isn't available";
        }

        return "";
    }

    export function cutPermissions(license: LicenseType, permissions: IPermissions): IPermissions {
        switch (license) {
            case LicenseType.None: return NONE_LICENSE_PERMISSIONS;
            case LicenseType.Regular: return permissions;
            case LicenseType.Viewer: return {
                common: CommonOperations.None,
                portfolio: cutToViewerPermissions(permissions.portfolio),
                program: cutToViewerPermissions(permissions.program),
                project: cutToViewerPermissions(permissions.project),
                objective: cutToViewerPermissions(permissions.objective),
                roadmap: cutToViewerPermissions(permissions.roadmap),
                challenge: cutToViewerPermissions(permissions.challenge)
            };
            default: return NONE_LICENSE_PERMISSIONS;
        }
    }

    function cutToViewerPermissions(permissions: IResourcePermissions) {
        return {
            layoutId: permissions.layoutId,
            global: permissions.global & (ResourceOperations.Read | ResourceOperations.Collaborate),
            entities: { ...permissions.entities, update: [] }
        }
    }

    export function mergePermissions(permissions1: IPermissions, permissions2: IPermissions): IPermissions {
        return {
            common: permissions1.common | permissions2.common,
            portfolio: mergeResourcePermissions(permissions1.portfolio, permissions2.portfolio),
            program: mergeResourcePermissions(permissions1.program, permissions2.program),
            project: mergeResourcePermissions(permissions1.project, permissions2.project),
            objective: mergeResourcePermissions(permissions1.objective, permissions2.objective),
            roadmap: mergeResourcePermissions(permissions1.roadmap, permissions2.roadmap),
            challenge: mergeResourcePermissions(permissions1.challenge, permissions2.challenge)
        }
    }

    export function appendPermissionsToRemove(permissions1: IPermissions, permissions2: IPermissions): IPermissions {
        return {
            common: permissions1.common,
            portfolio: addResourceGranularPermissionsToRemove(permissions1.portfolio, permissions2.portfolio),
            program: addResourceGranularPermissionsToRemove(permissions1.program, permissions2.program),
            project: addResourceGranularPermissionsToRemove(permissions1.project, permissions2.project),
            objective: addResourceGranularPermissionsToRemove(permissions1.objective, permissions2.objective),
            roadmap: addResourceGranularPermissionsToRemove(permissions1.roadmap, permissions2.roadmap),
            challenge: addResourceGranularPermissionsToRemove(permissions1.challenge, permissions2.challenge)
        }
    }

    function mergeResourcePermissions(permissions1: IResourcePermissions, permissions2: IResourcePermissions): IResourcePermissions {
        return {
            ...mergeResourceGranularPermissions(permissions1, permissions2),
            layoutId: permissions1.layoutId || permissions2.layoutId,
            global: permissions1.global | permissions2.global
        }
    }

    function mergeResourceGranularPermissions(permissions1: IResourcePermissions, permissions2: IResourcePermissions) {
        return {
            layoutId: permissions1.layoutId,
            global: permissions1.global,
            entities: {
                allIds: [...permissions1.entities.allIds ?? [], ...permissions2.entities.allIds ?? []].filter(distinct),
                read: distinctByKey([...permissions1.entities.read, ...permissions2.entities.read], "id"),
                collaborate: distinctByKey([...permissions1.entities.collaborate, ...permissions2.entities.collaborate], "id"),
                configure: distinctByKey([...permissions1.entities.configure, ...permissions2.entities.configure], "id"),
                update: distinctByKey([...permissions1.entities.update, ...permissions2.entities.update], "id"),
                layout: permissions1.entities.layout || permissions2.entities.layout
            }
        }
    }

    function addResourceGranularPermissionsToRemove(permissions1: IResourcePermissions, permissions2: IResourcePermissions) {
        return {
            layoutId: permissions1.layoutId,
            global: permissions1.global,
            entities: {
                ...permissions1.entities,
                allIds: [...permissions1.entities.allIds ?? [], ...permissions2.entities.allIds ?? []].filter(distinct),
            }
        }
    }

    function getAvailableCount(utilization: LicenseUtilization): number {
        return utilization.total - utilization.allocated;
    }
}
