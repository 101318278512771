import { CalculatedField, Field, FieldType, getOptions } from '../../entities/Metadata';
import { EntityType, IExtensibleEntity, StatusCategory } from '../../entities/common';
import { FormatDate, FormatDateTime, formatValue, isLockedField } from '../utils/common';
import { FieldsService } from './FieldsService';
import { SearchValue } from './SearchBox';
import { ViewService } from '../../services/ViewService';
import { UserState } from '../../store/User';
import { TenantState } from '../../store/Tenant';
import * as StatusDescriptorFactory from '../../entities/StatusDescriptorFactory';

export class SearchFieldService {
    public static searchFieldValue(field: Field, value: any, search: string): boolean {
        if (!search) {
            return false;
        }

        const isValueNullOrUndefined = value === undefined || value === null;
        if (isValueNullOrUndefined) {
            if (field.defaultValue !== undefined && field.defaultValue !== null) {
                value = field.defaultValue;
            } else if (field.settings?.editControl === "ColorStatusDropdown") {
                value = StatusDescriptorFactory.createStatusDescriptor(field).getCategoryDefaultStatusValue(StatusCategory.NA);
            }
            else if (field.type === FieldType.Flag) {
                value = false;
            } else {
                return false;
            }
        }

        if (Array.isArray(value)) {
            return value.find((_: any) => SearchFieldService.searchFieldValue(field, _, search)) !== undefined;
        }

        const searchValue = search.toLowerCase().trim();
        switch (field.type) {
            case FieldType.User:
            case FieldType.Resource: {
                return value.fullName?.toLowerCase().indexOf(searchValue) > -1;
            }
            case FieldType.Portfolio:
            case FieldType.Program:
            case FieldType.Project:
            case FieldType.Challenge:
            case FieldType.Idea:
            case FieldType.Objective:
            case FieldType.Task:
            case FieldType.Predecessor:
            case FieldType.Ref: {
                return value.name?.toLowerCase().indexOf(searchValue) > -1;
            }
            case FieldType.Text:
            case FieldType.Integer:
            case FieldType.Decimal: {
                if (FieldsService.isDropDown(field) || FieldsService.isSlider(field, true)) {
                    const valueOption = getOptions(field).find(_ => _.key.toString() === value?.toString());
                    return !!valueOption && valueOption.text.toLowerCase().indexOf(searchValue) !== -1;
                }
                if (field.type === FieldType.Text) {
                    return value.toLowerCase().replace(/[\n\r\t]/gm, " ").indexOf(searchValue) !== -1;
                }
                if (field.type === FieldType.Decimal && field.settings?.views?.list?.componentPath === "objective/Value") {
                    return `${value}`.toLowerCase().indexOf(searchValue) !== -1;
                }
                if (field.type === FieldType.Integer || field.type === FieldType.Decimal) {
                    const formattedValue = formatValue(value, field.settings?.format, false)
                    return formattedValue.toLowerCase().indexOf(searchValue) !== -1;
                }
                break;
            }
            case FieldType.Date: {
                const dateString = FormatDate(value);
                return !!dateString && dateString.toLowerCase().indexOf(searchValue) !== -1;

            }
            case FieldType.DateTime: {
                const dateTimeString = FormatDateTime(value, true);
                return !!dateTimeString && dateTimeString.toLowerCase().indexOf(searchValue) !== -1;
            }
            case FieldType.Flag: {
                return (value ? "yes" : "no").indexOf(searchValue) !== -1;
            }
            case FieldType.Group: {
                return value.name.toLowerCase().indexOf(searchValue) !== -1;
            }
            case FieldType.Hyperlink: {
                return value.text?.toLowerCase().includes(searchValue)
                    || value.url.toLowerCase().includes(searchValue);
            }
            default:
        }

        return false;
    }

    public static isItemVisible(search?: SearchValue, item?: IExtensibleEntity, getCalculatedFieldValue?: (field: CalculatedField) => any): boolean {
        return !search?.searchText || !item || SearchFieldService.searchValues(search, item, getCalculatedFieldValue);
    }

    public static searchByValue<T>(search: SearchValue, item: T, getValue: (item: T, field: Field) => any): boolean {
        return search.searchFields.some(field => SearchFieldService.searchFieldValue(field, getValue(item, field), search.searchText));
    }

    public static searchInProperties(search: SearchValue, item: any): boolean {
        return search.searchFields.some(field => SearchFieldService.searchFieldValue(field, item[field.name] ?? item.attributes[field.name], search.searchText));
    }

    public static searchValues(search: SearchValue, entity: IExtensibleEntity, getCalculatedFieldValue?: (field: CalculatedField) => any): boolean {
        return search.searchFields.some(field => {
            const value = SearchFieldService.getFieldValue(field, entity, search.isFieldFake, getCalculatedFieldValue)
            return SearchFieldService.searchFieldValue(field, value, search.searchText)
        });
    }

    public static canHandle(field: Field,
        isFieldFake?: (field: Field) => boolean,
        noSearchFields?: Field[],
        user?: UserState,
        entityType?: EntityType,
        tenant?: TenantState): boolean {
        if (noSearchFields?.includes(field)) {
            return false;
        }
        if (isLockedField(field.settings?.format, field.name, user, tenant, entityType)) {
            return false;
        }
        if (field.isFake || isFieldFake?.(field)) {
            return !!ViewService.getSearchValueHandler(field);
        }
        return true;
    }

    static getFieldValue(
        field: Field,
        item: IExtensibleEntity,
        isFieldFake?: (field: Field) => boolean,
        getCalculatedFieldValue?: (field: CalculatedField) => any
    ): any {
        if ((field as CalculatedField)?.calculate) {
            return getCalculatedFieldValue?.(field as CalculatedField);
        }
        const handler = ViewService.getSearchValueHandler(field);
        if (handler) {
            return handler(item, field);
        }
        else if (field.isFake || isFieldFake?.(field)) {
            console.warn('Search value extractor map does not contain definition for field "' + field.name + '"');
            return undefined;
        }
        return item.attributes[field.name];
    }
}