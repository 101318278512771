import * as React from "react";
import { BaseFilterValue, IFilter, Filter, IFilterHelper, FilterAttribute, Field, IActiveFilter, getLabel, IEntityFilterHelper } from "../../entities/Metadata";
import { Dictionary } from "../../entities/common";
import { DisplayFieldService } from "../../components/common/DisplayFieldService";
import { FieldsService } from "../../components/common/FieldsService";
import { IUser } from "../UsersListStore";
import { toCamelCase } from "../../components/utils/common";
import { create } from "domain";

export interface UserFilterValue extends BaseFilterValue {

}

export class ActiveFilter implements IActiveFilter {
    private readonly _filter: IFilter<UserFilterValue>;

    constructor(name?: string) {
        this._filter = Filter.empty(name);
    }

    public build(): IFilter<UserFilterValue> {
        return this._filter;
    }

    public buildLocationState = (preFilterId?: string | null): LocationState => {
        return {
            filter: this._filter,
            preFilterId: preFilterId
        };
    }
}

export type LocationState = {
    filter?: IFilter<BaseFilterValue>;
    preFilterId?: string | null;
}

export type UserFilterAttribute = FilterAttribute<UserFilterValue>;

export type FilterHelperProps = {
    userFields: Field[];
    resourceFields: Field[];
}

export class FilterHelper implements IEntityFilterHelper<IUser> {

    private readonly _props: FilterHelperProps

    public readonly UserFields: Field[];
    public readonly ResourceFields: Field[];

    private attributes: IFilterHelper<UserFilterValue, IUser>;
    private resourceAttributes: IFilterHelper<UserFilterValue, IUser>;
    public helpersMap: { [K in keyof UserFilterValue]: IFilterHelper<UserFilterValue, IUser> };
    public newFilter = (name: string): IActiveFilter => new ActiveFilter(name);

    constructor(props: FilterHelperProps) {
        this._props = props;
        this.UserFields = props.userFields;
        this.ResourceFields = props.resourceFields;

        this.attributes = this.createHelper(
            this._props.userFields,
            (filter, field) => filter.value?.attributes?.[field.name],
            (user, key) => user[toCamelCase(key)]
        );

        this.resourceAttributes = this.createHelper(
            this._props.resourceFields,
            (filter, field) => filter.value?.resourceAttributes?.[field.name],
            (user, key) => user.resourceAttributes?.[key]
        );
        this.helpersMap =
        {
            "attributes": this.attributes,
            "resourceAttributes": this.resourceAttributes,
        }
    }

    public filter(users: IUser[], activeFilter?: IFilter<UserFilterValue> | undefined): IUser[] {
        const isItemVisible = this.isItemVisibleBuilder(activeFilter);
        return users.filter(isItemVisible);
    }

    private isItemVisibleBuilder = (activeFilter?: IFilter<UserFilterValue> | undefined) => (item: IUser): boolean => {
        if (activeFilter) {
            const filterValue = activeFilter.value;

            const allAttributes = this.getFilterAttributes();

            for (const [type, value] of Object.entries(filterValue)) {
                const validateItem = this.helpersMap[type]
                    .validateItem(item, value, allAttributes.filter(_ => _.type === type));
                if (!validateItem) {
                    return false;
                }
            }
        }

        return true;
    }

    public getFilterAttributes = (): FilterAttribute<BaseFilterValue>[] => {
        const attrs = (this._props.userFields
            .map(_ => ({ type: "attributes", value: _, name: _.name, displayName: getLabel(_) }) as FilterAttribute<BaseFilterValue>))
            .concat(this._props.resourceFields.map(_ => ({ type: "resourceAttributes", value: _, name: _.name, displayName: getLabel(_) }) as FilterAttribute<BaseFilterValue>))
        return attrs;
    }

    private createHelper = (
        fields: Field[],
        getFilterValue: (filter: IFilter<UserFilterValue>, field: Field) => any,
        getValue: (user: IUser, key: string) => any
    ): IFilterHelper<UserFilterValue, IUser> => {
        return {
            buildFilterElement: (attr: FilterAttribute<UserFilterValue>,
                filter: IFilter<UserFilterValue>,
                onFilterEditComplete: (type: string | number, name: string, value: any) => void
            ): JSX.Element | null => {
                const field: Field = attr.value;
                const value = getFilterValue(filter, field);

                return DisplayFieldService.buildFieldMultiSelectInput(field, value, changed => onFilterEditComplete(attr.type, field.name, changed));
            },
            removeFilterAttribute: (attrName: string, typeValue: any) => {
                const newValue: Dictionary<any> = {};
                Object.keys(typeValue).forEach(vk => {
                    if (vk !== attrName) {
                        newValue[vk] = typeValue[vk];
                    }
                });
                return newValue;
            },
            setAttributeValue: (attrName: string, value: any, oldValue: any) => {
                if (!oldValue) {
                    oldValue = {};
                }
                const tmpValue = { ...oldValue };
                tmpValue[attrName] = value;
                return tmpValue;
            },
            getAttributeValues: (value: any): string[] => FieldsService.getAttributeDisplayValues(fields, value),
            validateItem: (item: IUser, filterValue: any, attributes: any[]): boolean => {
                if (!filterValue) {
                    return false;
                }
                for (const key in filterValue) {
                    if (filterValue.hasOwnProperty(key)) {
                        if (filterValue[key] == undefined || (Array.isArray(filterValue[key]) && filterValue[key].length === 0)) {
                            continue;
                        }

                        const attribute = attributes.find(_ => _.name === key);
                        if (!attribute) {
                            return false;
                        }
                        const field = attribute.value;
                        const value = getValue(item, key);

                        if (!FieldsService.compareFieldValues(field, value, filterValue[key])) {
                            return false;
                        }
                    }
                }
                return true;
            }
        }
    }
}
