import * as React from 'react';
import { IColumn } from 'office-ui-fabric-react';
import createLazyContainer from '../lazyContainer';
import { Dictionary, EntityType, IBaseEntity, IExtensibleEntity, IWithName, SortDirection } from "../entities/common";
import * as Metadata from "../entities/Metadata";
import { IOrderBy } from '../store/views';
import { DisplayField } from '../components/common/DisplayField';
import { toDictionaryByName } from '../components/utils/common';
import { namesof } from '../store/services/metadataService';
import { IDependencyAttrs, isLinkedSubentity, ISteeringCommitteeAttrs } from '../entities/Subentities';
import ProjectBudgetTotals from "../components/views/list/columns/project/BudgetTotals";
import ProjectBenefitTotals from "../components/views/list/columns/project/BenefitTotals";
import PortfolioPercentCompleted from "../components/views/list/columns/portfolio/PercentCompleted";
import PortfolioPercentCostCompleted from "../components/views/list/columns/portfolio/PercentCostCompleted";
import PortfolioBudgetTotals from "../components/views/list/columns/portfolio/BudgetTotals";
import PortfolioBenefitTotals from "../components/views/list/columns/portfolio/BenefitTotals";
import ProgramPercentCompleted from "../components/views/list/columns/program/PercentCompleted";
import ProgramPercentCostCompleted from "../components/views/list/columns/program/PercentCostCompleted";
import ProgramBudgetTotals from "../components/views/list/columns/program/BudgetTotals";
import ProgramBenefitTotals from "../components/views/list/columns/program/BenefitTotals";
import { default as ProgramLinkedSystem } from "../components/views/list/columns/program/LinkedSystem";
import * as Favorite from "../components/views/list/columns/project/Favorite";
import * as ProjectVisibility from '../components/views/list/columns/project/ProjectVisibility';
import { default as ProjectLinkedSystem } from "../components/views/list/columns/project/LinkedSystem";
import { default as ResourceLinkedSystem } from "../components/views/list/columns/resource/LinkedSystem";
import { default as LastADSync } from "../components/views/list/columns/resource/LastADSync";
import { default as ActiveDirectoryId } from "../components/views/list/columns/resource/ActiveDirectoryId";
import { default as ChallengeIdeas } from "../components/views/list/columns/challenge/Ideas";
import * as Priority from "../components/views/list/columns/insights/Priority";
import SyncDate from "../components/views/list/columns/project/SyncDate";
import SyncStatus from "../components/views/list/columns/project/SyncStatus";
import SyncDetails from "../components/views/list/columns/project/SyncDetails";
import InsightsLate from '../components/views/list/columns/insights/Late';
import MyWorkStatus from '../components/views/list/columns/mywork/Status';
import { getSearchValue as ObjectiveDirectionGetSearchValue } from '../components/views/list/columns/objective/Direction';
import { getSearchValue as ObjectiveValueGetSearchValue } from '../components/views/list/columns/objective/Value';
import { getSearchValue as PortfolioGetSearchValue } from '../components/views/list/columns/project/Portfolio';
import { getValue as IsSummaryGetValue, getSearchValue as IsSummaryGetSearchValue } from '../components/views/list/columns/task/IsSummary';
import { getValue as TaskModeGetValue, getSearchValue as TaskModeGetSearchValue } from '../components/views/list/columns/task/Mode';
import { getValue as ImportedFromGetValue } from '../components/views/list/columns/subentity/ImportedFrom';
import { getValue as TaskBaselineGetValue, getSearchValue as TaskBaselineGetSearchValue } from '../components/views/list/columns/task/Baseline';
import { getValue as KeyDateBaselineGetValue, getSearchValue as KeyDateBaselineGetSearchValue } from '../components/views/list/columns/keyDate/Baseline';
import { getValue as TasksGetValue, getSearchValue as TasksGetSearchValue  } from '../components/views/list/columns/project/Tasks';
import { getValue as VotesGetValue, getSearchValue as VotesGetSearchValue } from "../components/views/list/columns/idea/Votes";
import { getValue as GetLayoutName } from "../components/views/list/columns/LayoutName";

const columns = import.meta.glob('../components/views/list/columns/**/*.tsx');
const componentCache = new Dictionary<any>();

const fakeFieldsMap: Dictionary<{ getValue: ((entity: { attributes: Dictionary<any> }, field?: Metadata.Field) => any) | undefined;
    getSearchValue: ((entity: IExtensibleEntity, field?: Metadata.Field) => any) | undefined }> =
{
    "portfolio/PercentCompleted": { getValue: PortfolioPercentCompleted.getValue, getSearchValue: PortfolioPercentCompleted.getSearchValue },
    "portfolio/PercentCostCompleted": { getValue: PortfolioPercentCostCompleted.getValue, getSearchValue: PortfolioPercentCostCompleted.getSearchValue },
    "portfolio/BudgetTotals": { getValue: PortfolioBudgetTotals.getValue, getSearchValue: PortfolioBudgetTotals.getSearchValue },
    "portfolio/BenefitTotals": { getValue: PortfolioBenefitTotals.getValue, getSearchValue: PortfolioBenefitTotals.getSearchValue },
    "program/PercentCompleted": { getValue: ProgramPercentCompleted.getValue, getSearchValue: ProgramPercentCompleted.getSearchValue },
    "program/PercentCostCompleted": { getValue: ProgramPercentCostCompleted.getValue, getSearchValue: ProgramPercentCostCompleted.getSearchValue },
    "program/BudgetTotals": { getValue: ProgramBudgetTotals.getValue, getSearchValue: ProgramBudgetTotals.getSearchValue },
    "program/BenefitTotals": { getValue: ProgramBenefitTotals.getValue, getSearchValue: ProgramBenefitTotals.getSearchValue },
    "program/LinkedSystem": { getValue: ProgramLinkedSystem.getValue, getSearchValue: ProgramLinkedSystem.getSearchValue },
    "project/BudgetTotals": { getValue: ProjectBudgetTotals.getValue, getSearchValue: ProjectBudgetTotals.getSearchValue },
    "project/BenefitTotals": { getValue: ProjectBenefitTotals.getValue, getSearchValue: ProjectBenefitTotals.getSearchValue },
    "project/Favorite": { getValue: Favorite.getValue, getSearchValue: Favorite.getSearchValue },
    "project/ProjectVisibility": { getValue: ProjectVisibility.getValue, getSearchValue: ProjectVisibility.getSearchValue },
    "project/Portfolio": { getValue: undefined, getSearchValue: PortfolioGetSearchValue },
    "project/LinkedSystem": { getValue: ProjectLinkedSystem.getValue, getSearchValue: ProjectLinkedSystem.getSearchValue },
    "project/Tasks": { getValue: TasksGetValue, getSearchValue: TasksGetSearchValue },
    "project/SyncDate": { getValue: SyncDate.getValue, getSearchValue: SyncDate.getSearchValue },
    "project/SyncStatus": { getValue: SyncStatus.getValue, getSearchValue: SyncStatus.getSearchValue },
    "project/SyncDetails": { getValue: SyncDetails.getValue, getSearchValue: SyncDetails.getSearchValue },
    "resource/LinkedSystem": { getValue: ResourceLinkedSystem.getValue, getSearchValue: ResourceLinkedSystem.getSearchValue },
    "resource/LastADSync": { getValue: LastADSync.getValue, getSearchValue: LastADSync.getSearchValue },
    "resource/ActiveDirectoryId": { getValue: ActiveDirectoryId.getValue, getSearchValue: ActiveDirectoryId.getSearchValue },
    "challenge/Ideas": { getValue: ChallengeIdeas.getValue, getSearchValue: ChallengeIdeas.getSearchValue },
    "idea/Votes": { getValue: VotesGetValue, getSearchValue: VotesGetSearchValue },
    "insights/Late": { getValue: InsightsLate.getValue, getSearchValue: InsightsLate.getSearchValue },
    "mywork/Status": { getValue: MyWorkStatus.getValue, getSearchValue: MyWorkStatus.getSearchValue },
    "keyDate/Baseline": { getValue: KeyDateBaselineGetValue, getSearchValue: KeyDateBaselineGetSearchValue },
    "insights/Priority": { getValue: Priority.getValue, getSearchValue: Priority.getSearchValue },
    "task/Baseline": { getValue: TaskBaselineGetValue, getSearchValue: TaskBaselineGetSearchValue },
    "task/Mode": { getValue: TaskModeGetValue, getSearchValue: TaskModeGetSearchValue },
    "task/IsSummary": { getValue: IsSummaryGetValue, getSearchValue: IsSummaryGetSearchValue },
    "objective/Direction": { getValue: undefined, getSearchValue: ObjectiveDirectionGetSearchValue },
    "objective/Value": { getValue: undefined, getSearchValue: ObjectiveValueGetSearchValue },
    "subentity/ImportedFrom": { getValue: ImportedFromGetValue, getSearchValue: undefined },
    "LayoutName": { getValue: GetLayoutName, getSearchValue: undefined }
};

export class ViewService {
    static createListColumn<T>(componentName: string) {
        let component = componentCache[componentName];
        if (!component) {
            const loader = columns[`../components/views/list/columns/${componentName}.tsx`]()
                .then((module: any) => ({ default: module.default }));
            component = componentCache[componentName] = createLazyContainer<T>(() => loader);
        }

        return component;
    }

    public static getHandler(field: Metadata.Field) {
        const componentPath: string | undefined = field.settings?.views?.list?.componentPath;
        if (!componentPath) {
            return undefined;
        }
        
        return fakeFieldsMap[componentPath]?.getValue;
    }

    public static isActionable(field: Metadata.Field): boolean {
        return !!field.settings?.views?.list?.isActionable;
    }

    static getFakeFieldColumnValue(entity: { attributes: Dictionary<any> }, field: Metadata.Field): any {
        const handler = ViewService.getHandler(field);
        if (!handler) {
            console.warn('Value extractor map does not contain definition for field "' + field.name + '"');
            return undefined;
        }

        return handler(entity, field);
    }

    static getFieldName(column: string | Metadata.ISubViewColumn) {
        return typeof column === "string" ? column : column.id;
    }

    static buildColumn(
        fieldName: string,
        mapByName: Dictionary<Metadata.Field>,
        orderBy: IOrderBy | IOrderBy[] | undefined,
        isTimelineView: boolean,
        entityType: EntityType,
        columns?: Metadata.ISubViewColumn[],
        disableNavigation?: boolean,
        isArchived?: boolean
    ): IColumn | null {
        const field = mapByName[fieldName];
        if (!field) {
            return null;
        }

        const isMultiline = ViewService.isFieldMultiline(field);
        const defaultSettings = {
            isMultiline,
            minWidth: (isMultiline || field.type === Metadata.FieldType.Resource || field.type === Metadata.FieldType.User) ? 200 : 100,
            maxWidth: 300,
            isResizable: true
        }
        const fieldSettings = Object.assign({}, defaultSettings, field.settings?.views?.list || {});
        const origin = columns && columns.find(_ => _.id === field.id);
        const columnSettings = origin
            ? {
                ...fieldSettings,
                maxWidth: origin.width || fieldSettings.maxWidth,
                minWidth: origin.width || fieldSettings.minWidth
            }
            : fieldSettings;

        const fieldSort = orderBy && (Array.isArray(orderBy)
            ? orderBy.find(_ => _.fieldName === field.name)
            : orderBy.fieldName === field.name
                ? orderBy
                : undefined);

        disableNavigation ||= isArchived && field.type === Metadata.FieldType.Resource;

        return {
            ...columnSettings,
            key: field.id,
            name: columnSettings.label !== undefined ? columnSettings.label : Metadata.getLabel(field),
            fieldName: field.name,
            onRender: (item?: IExtensibleEntity, index?: number, column?: IColumn) => {
                if (columnSettings.componentPath) {
                    const component = ViewService.createListColumn<IListViewFieldColumn<IExtensibleEntity>>(columnSettings.componentPath);
                    return React.createElement(component, { entity: item, field, className: "font-14", isTimelineView, entityType, disableNavigation });
                }
                return React.createElement(
                    'span',
                    { className: "list-grid-cell" },
                    item ? React.createElement(DisplayField, { field, entity: item, entityType, disableNavigation }) : undefined);
            },
            isSorted: !!fieldSort,
            isSortedDescending: !!fieldSort && fieldSort.direction === SortDirection.DESC,
            headerClassName: columnSettings?.iconName ? "with-icon" : undefined
        };
    }

    static buildCellRenderer<T>(item: T, entityType: EntityType, fields: Metadata.Field[], field: Metadata.Field, isFieldFake: (field: Metadata.Field) => boolean) {
        const fieldName = Metadata.findMatchingFieldName(fields, field, isFieldFake);
        return this.renderCell(fieldName
            ? ViewService.buildColumn(fieldName, toDictionaryByName(fields), undefined, true, entityType)
            : null, item);
    }

    static renderCell(column: IColumn | null, item?: any) {
        return column ? column.onRender!(item, undefined, column) : React.createElement(React.Fragment);
    }

    static isMenuColumn(entityType: EntityType, column: string | Metadata.ISubViewColumn): boolean {
        const mandatories = ViewService.getViewMandatoryFields(entityType);
        return mandatories?.length
            ? mandatories[0] === ViewService.getFieldName(column)
            : false;
    }

    static getViewMandatoryFields = (entityType: EntityType): string[] => {
        switch (entityType) {
            case EntityType.Portfolio:
            case EntityType.Program:
            case EntityType.Project:
            case EntityType.KeyDate:
            case EntityType.ActionItem:
            case EntityType.LessonLearned:
            case EntityType.ChangeRequest:
            case EntityType.Issue:
            case EntityType.Iteration:
            case EntityType.KeyDecision:
            case EntityType.PurchaseOrder:
            case EntityType.Invoice:
            case EntityType.Risk:
            case EntityType.StrategicPriority:
            case EntityType.Resource:
            case EntityType.Challenge:
            case EntityType.Idea:
            case EntityType.Roadmap:
            case EntityType.RoadmapItem:
            case EntityType.Objective:
            case EntityType.KeyResult:
            case EntityType.Task:
            case EntityType.Deliverable:
            case EntityType.ArchivedProject:
            case EntityType.TimeTrackingEntry:
            case EntityType.MyWork:                
                return namesof<IWithName>(["Name"]);
            case EntityType.Dependency:
                return namesof<IDependencyAttrs>(["Type"]);
            case EntityType.SteeringCommittee:
                return namesof<ISteeringCommitteeAttrs>(["Person"]);
            default:
                return [];
        }
    }

    static isMandatory = (field: Metadata.Field, mandatoryFields?: string[]): boolean =>
        !!mandatoryFields?.includes(field.name);

    static isReadonlyField = (
        field: Metadata.Field,
        readOnlyFields: string[] | ((entity: any) => string[]) | undefined,
        entityType: string,
        entity: any | undefined,
        editableNativeFieldsForLinkedEntities?: ((entity: any) => string[]) | string[],
        theOnlyEditableFieldsForLinkedEntities?: string[],
    ): boolean => {
        return !!readOnlyFields && includesField(readOnlyFields)
            || (isLinkedSubentity(entityType, entity)
                && !!theOnlyEditableFieldsForLinkedEntities && !theOnlyEditableFieldsForLinkedEntities.includes(field.name))
            || (field.isNative && !!entityType && isLinkedSubentity(entityType, entity)
                && (!editableNativeFieldsForLinkedEntities || !includesField(editableNativeFieldsForLinkedEntities))
            );

        function includesField(fieldNames: string[] | ((entity: any) => string[])): boolean {
            return Array.isArray(fieldNames) && fieldNames.includes(field.name)
                || !Array.isArray(fieldNames) && fieldNames(entity).includes(field.name);
        }
    }

    static isFieldMultiline = (field: Metadata.Field): boolean => !!field.settings?.multiline;

    static buildBulkUpdates = (item: IBaseEntity, field: Metadata.Field, value: any, extraUpdates?: Dictionary<any>): Dictionary<any> => {
        return { [item.id]: ViewService.buildUpdates(field, value, extraUpdates) };
    }

    static buildUpdates = (field: Metadata.Field, value: any, extraUpdates?: Dictionary<any>): Dictionary<any> => {
        return { ...extraUpdates, [field.name]: value };
    }

    public static getSearchValueHandler(field: Metadata.Field) {
        const componentPath: string | undefined = field.settings?.views?.list?.componentPath;
        if (!componentPath) {
            return undefined;
        }

        return fakeFieldsMap[componentPath]?.getSearchValue;
    }
}

export interface IListViewColumn<TEntity> {
    entity: TEntity;
    className?: string;
    isTimelineView?: boolean;
    entityType?: EntityType;
    disableNavigation?: boolean;
}

export interface IListViewFieldColumn<TEntity> extends IListViewColumn<TEntity> {
    field: Metadata.Field;
}