import "./EditableGridCell.css";
import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { DisplayFieldService } from "../DisplayFieldService";
import { useDebounce, useOuterClick } from "../../utils/effects";
import { GridCellProps } from "./EditableGridCell";
import { Dictionary, IBaseEntity } from "../../../entities/common";
import { UserState } from "../../../store/User";
import { TenantState } from "../../../store/Tenant";
import { ViewService } from "../../../services/ViewService";
import { getId } from "office-ui-fabric-react";
import ErrorMessageCallout from "./ErrorMessageCallout";

type StateProps = {
    user: UserState;
    tenant: TenantState;
}

const CellEditor = (props: GridCellProps<IBaseEntity> & StateProps & { onClose: (error?: string) => void }) => {
    const [value, setValue] = React.useState(props.getItemValue(props.item, props.field));
    const [extra, setExtra] = React.useState<Dictionary<any> | undefined>();
    const [isChanged, setIsChanged] = React.useState(false);

    const validator = React.useMemo(() => {
        return props.customFieldValidatorBuilder?.hasOwnProperty(props.field.name)
            ? props.customFieldValidatorBuilder[props.field.name]?.(props.item, props.field)
            : DisplayFieldService.buildValidatorFromField(props.field);
    }, [props.item, props.field, props.customFieldValidatorBuilder]);

    const _onEditComplete = useDebounce<(newValue?: any, extra?: Dictionary<any>) => void>((newValue?: any, newExtra?: Dictionary<any>) => {
        let val = value;
        let extr = extra;
        let error: string | undefined = undefined;
        if (newValue != null) {
            setValue(newValue);
            setExtra(extra);
            setIsChanged(true);
            val = newValue;
            extr = newExtra;
        }

        if (!validator || validator.isValid(val)) {
            if (isChanged) {
                props.onEditComplete(val!, extr);
            }
        } else {
            error = isChanged ? validator.getErrorMessage(val) : undefined;
            setValue(props.getItemValue(props.item, props.field));
            setExtra(undefined);
            setIsChanged(false);
        }
        props.onClose(error);
    }, 100);

    const listener = React.useCallback(() => _onEditComplete(), []);

    const ref = useOuterClick<HTMLDivElement>(listener, ["ms-Modal", "remove-dialog", "picker-item"]);
    const id = React.useMemo(() => getId(), []);
    const inputProps = {
        value: value,
        item: props.item,
        onChanged: (newValue: any, newExtra?: Dictionary<any>) => {
            setValue(newValue);
            setExtra(newExtra);
            setIsChanged(true);
        },
        onBlur: () => _onEditComplete(),
        onEditComplete: _onEditComplete,
        inputRef: (_: any) => { _?.focus(); },
        onErrorRender: (error: JSX.Element | string) => <ErrorMessageCallout controlId={id} error={error} />
    };

    const editor = React.useMemo(() => {
        return props.customFieldElementRender?.hasOwnProperty(props.field.name)
            ? props.customFieldElementRender[props.field.name]?.(inputProps, props.item, props.field, validator)
            : DisplayFieldService.buildGridEditorFieldInputElement(props.field, inputProps, props.entityType, props.user, props.tenant, validator);
    }, [props.customFieldElementRender, props.customFieldValidatorBuilder, props.field, props.item, props.entityType, value, props.user, props.tenant]);

    const onKeyDown = React.useCallback(e => {
        const isMultiline = ViewService.isFieldMultiline(props.field);

        if (isMultiline && e.key === 'Enter' && e.shiftKey) {
            return;
        }
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            _onEditComplete();
        }

        if (e.key === 'Escape') {
            setValue(props.getItemValue(props.item, props.field));
            setExtra(undefined);
            setIsChanged(false);
            e.preventDefault();
            e.stopPropagation();
            props.onClose();
        }
    }, [props.field, props.item]);

    return <div ref={ref} id={id}
        className={`grid-editable-cell in-edit-mode ${props.className ?? ''}`}
        data-selection-disabled
        onClick={e => e.stopPropagation()}
        onKeyDown={onKeyDown} >
        {editor}
    </div>;
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        user: state.user,
        tenant: state.tenant
    };
}

export default connect(mapStateToProps)(CellEditor)